.cropper {
  position: relative;
  height: 50vh;
}

.viewer {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.output {
  position: relative;
  width: 300px;
  overflow: hidden;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.3);
}

.output img {
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top left;
}

.card-allignment {
  width: calc(25% - 20px);
}

.order-cards {
  --Grid-columns: 12;
  --Grid-columnSpacing: 24px;
  --Grid-rowSpacing: 24px;
  flex-flow: wrap;
  min-width: 0px;
  box-sizing: border-box;
  display: flex;
  margin: calc(24px / -2) calc(24px / -2);
}
.order-cards .order-first-card {
  -webkit-box-flow: 0;
  flex-grow: 0;
  flex-basis: auto;
  width: calc(100% * 12 / 12);
  min-width: 0;
  box-sizing: border-box;
  padding: calc(24px / 2) calc(24px / 2);
}
.order-cards .order-second-card {
  flex-grow: 0;
  flex-basis: auto;
  width: calc(100% * 12 / var(--Grid-columns));
}

form input[type="number"]::-webkit-inner-spin-button {
  display: none;
}

@media (min-width: 900px) {
  .order-cards .order-first-card {
    width: calc(100% * 8 / var(--Grid-columns));
  }
  .order-cards .order-second-card {
    width: calc(100% * 4 / 12);
  }
}

@media (max-width: 800px) {
  .card-allignment {
    width: calc(33.33% - 20px); /* 3 cards per row */
  }
}
@media (max-width: 650px) {
  .card-allignment {
    width: calc(50% - 20px); /* 3 cards per row */
  }
}
@media (max-width: 450px) {
  .card-allignment {
    width: calc(100% - 20px); /* 3 cards per row */
  }
}
