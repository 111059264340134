.ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  font-family: "Poppins";
  /* background: #f1f1f1; */
}
.ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}
.ql-editor {
  min-height: 168px;
  max-height: 640px;
}

/* image uploader styles */

.image-uploader {
  /* width: 300px; */
  /* height: 200px; */
  border: 2px dashed #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 10px;
}

.selected-image {
  max-width: 100%;
  max-height: 100%;
}

.upload-prompt {
  text-align: center;
}

/* .file-input-label { */
/* display: inline-block;
  background-color: #4287f5;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer; */
/* } */

.file-input {
  display: none;
}

.show-modal {
  outline: none;
  border: none;
  cursor: pointer;
  color: #fff;
  border-radius: 6px;
  font-size: 1.2rem;
  padding: 15px 22px;
  background: #4a98f7;
  transition: 0.3s ease;
  box-shadow: 0 10px 18px rgba(52, 87, 220, 0.18);
}
.show-modal:hover {
  background: #2382f6;
}
.bottom-sheet {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 0;
  pointer-events: none;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  transition: 0.1s linear;
}
.bottom-sheet.show {
  opacity: 1;
  pointer-events: auto;
}
.bottom-sheet .sheet-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  background: #000;
}
.bottom-sheet .content {
  width: 100%;
  position: relative;
  background: #fff;
  max-height: 100vh;
  height: 50vh;
  max-width: 1150px;
  padding: 25px 30px;
  transform: translateY(100%);
  border-radius: 12px 12px 0 0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.03);
  transition: 0.3s ease;
}
.bottom-sheet.show .content {
  transform: translateY(0%);
}
.bottom-sheet.dragging .content {
  transition: none;
}
.bottom-sheet.fullscreen .content {
  border-radius: 0;
  overflow-y: hidden;
}
.bottom-sheet .header {
  display: flex;
  justify-content: center;
}
.header .drag-icon {
  cursor: grab;
  user-select: none;
  padding: 15px;
  margin-top: -15px;
}
.header .drag-icon span {
  height: 4px;
  width: 40px;
  display: block;
  background: #c7d0e1;
  border-radius: 50px;
}
.bottom-sheet .body {
  height: 100%;
  overflow-y: auto;
  padding: 15px 0 40px;
  scrollbar-width: none;
}
.bottom-sheet .body::-webkit-scrollbar {
  width: 0;
}
.bottom-sheet .body h2 {
  font-size: 1.8rem;
}
.bottom-sheet .body p {
  margin-top: 20px;
  font-size: 1.05rem;
}

.nav-links {
  display: block;
}
.nav-bar ul li a {
  outline: none;
}
.nav-bar ul li a:hover {
  border-top: 3px solid white;
  border-bottom: 3px solid white;
}

@media (max-width: 640px) {
  .nav-links {
    display: none;
  }
}
