.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 10%));
  /* grid-template-columns: repeat(4, 150px); */
  gap: 20px;
}
.card {
  background-color: #fffaef;
}
.card img {
  aspect-ratio: 1/1;
  padding: 8px;
  object-fit: cover;
}

.search-cards {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); */
  grid-template-columns: repeat(auto-fit, minmax(100px, 15%));
  gap: 15px;
  justify-content: center;
}
.search-card {
  background-color: #fffaef;
}
.search-card img {
  aspect-ratio: 1/1;
  padding: 10px;
  object-fit: contain;
}

.product-category-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 15%));
  gap: 15px;
}
.product-category-cards .product-category-card {
  background-color: #fffaef;
}
.product-category-cards .product-category-card img {
  aspect-ratio: 1/1;
  padding: 10px;
  object-fit: cover;
}

.profile-btns {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.product-details-cards {
  --Grid-columns: 12;
  --Grid-columnSpacing: 24px;
  --Grid-rowSpacing: 24px;
  flex-flow: wrap;
  min-width: 0px;
  box-sizing: border-box;
  display: flex;
  margin: calc(24px / -2) calc(24px / -2);
}
.product-details-cards .product-details-first-card {
  -webkit-box-flow: 0;
  flex-grow: 0;
  flex-basis: auto;
  width: calc(100% * 12 / 12);
  min-width: 0;
  box-sizing: border-box;
  /* padding: calc(24px / 2) calc(24px / 2); */
}
.product-details-cards .product-details-second-card {
  flex-grow: 0;
  flex-basis: auto;
  width: calc(100% * 12 / var(--Grid-columns));
}

.cart-details-cards {
  --Grid-columns: 12;
  --Grid-columnSpacing: 24px;
  --Grid-rowSpacing: 24px;
  flex-flow: wrap;
  min-width: 0px;
  box-sizing: border-box;
  display: flex;
  margin: calc(24px / -2) calc(24px / -2);
}
.cart-details-cards .cart-details-first-card {
  -webkit-box-flow: 0;
  flex-grow: 0;
  flex-basis: auto;
  width: calc(100% * 12 / 12);
  min-width: 0;
  box-sizing: border-box;
  /* padding: calc(24px / 2) calc(24px / 2); */
}
.cart-details-cards .cart-details-second-card {
  flex-grow: 0;
  flex-basis: auto;
  width: calc(100% * 12 / var(--Grid-columns));
}
.payment-selection-cards {
  --Grid-columns: 12;
  --Grid-columnSpacing: 24px;
  --Grid-rowSpacing: 24px;
  flex-flow: wrap;
  min-width: 0px;
  box-sizing: border-box;
  display: flex;
  padding: 20px 50px;
  /* margin: calc(24px / -2) calc(24px / -2); */
}
.payment-selection-cards .payment-selection-first-card {
  -webkit-box-flow: 0;
  flex-grow: 0;
  flex-basis: auto;
  width: calc(100% * 12 / 12);
  min-width: 0;
  box-sizing: border-box;
  /* padding: calc(24px / 2) calc(24px / 2); */
}
.payment-selection-cards .payment-selection-second-card {
  flex-grow: 0;
  flex-basis: auto;
  width: calc(100% * 12 / var(--Grid-columns));
}
/* 
.MuiTabs-indicator {
  background: #c3c3c3 !important;
}
.MuiTabs {
  background-color: #fbb003;
}
.Mui-selected,
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  color: #000 !important;
  border: 1px solid #fbb003;
} */

.subCategoryScroll::-webkit-scrollbar {
  display: none;
}

@media (min-width: 900px) {
  .product-details-cards .product-details-first-card {
    width: calc(100% * 6 / var(--Grid-columns));
  }
  .product-details-cards .product-details-second-card {
    width: calc(100% * 6 / var(--Grid-columns));
  }
  .cart-details-cards .cart-details-first-card {
    width: calc(100% * 8 / var(--Grid-columns));
  }
  .cart-details-cards .cart-details-second-card {
    width: calc(100% * 4 / var(--Grid-columns));
  }
  .payment-selection-cards .payment-selection-first-card {
    width: calc(100% * 8 / var(--Grid-columns));
  }
  .payment-selection-cards .payment-selection-second-card {
    width: calc(100% * 4 / var(--Grid-columns));
  }
}

@media (max-width: 1500px) {
  .cards {
    grid-template-columns: repeat(auto-fit, minmax(100px, 15%));
  }
  .search-cards {
    grid-template-columns: repeat(auto-fit, minmax(100px, 23%));
    justify-content: flex-start;
  }
  .product-category-cards {
    grid-template-columns: repeat(auto-fit, minmax(100px, 23%));
  }
}

@media (max-width: 1000px) {
  .search-cards {
    grid-template-columns: repeat(auto-fit, minmax(100px, 30%));
  }
  .product-category-cards {
    grid-template-columns: repeat(auto-fit, minmax(100px, 30%));
  }
}

@media (max-width: 640px) {
  .cards {
    grid-template-columns: repeat(auto-fit, minmax(100px, 31%));
    gap: 10px;
  }
  .search-cards {
    grid-template-columns: repeat(auto-fit, minmax(150px, 0.5fr));
    gap: 10px;
  }
  .product-category-cards {
    grid-template-columns: repeat(auto-fit, minmax(150px, 0.5fr));
    gap: 10px;
  }
  .profile-btns {
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    gap: 20px;
  }
  .payment-selection-cards {
    padding: 10px;
  }
}

@media (max-width: 350px) {
  .cards {
    grid-template-columns: repeat(auto-fit, minmax(100px, 48%));
    gap: 10px;
  }
}

.image-gallery {
  width: 100%;
  /* max-width: 950px; */
  margin: 0 auto;
  /* padding: 50px 20px; */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-rows: 250px;
  grid-auto-flow: dense;
  grid-gap: 20px;
}

.image-gallery .image-box {
  position: relative;
  background-color: #d7d7d8;
  overflow: hidden;
}

.image-gallery .image-box:nth-child(7n + 1) {
  grid-column: span 2;
  grid-row: span 2;
}

.image-gallery .image-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.5s ease;
}

.image-gallery .image-box:hover img {
  transform: scale(1.1);
}

.image-gallery .image-box .overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fafafaf2;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.5s ease;
  z-index: 1;
}

.image-gallery .image-box:hover .overlay {
  top: 20px;
  right: 20px;
  bottom: 20px;
  left: 20px;
  opacity: 1;
}

.image-gallery .image-box .details {
  text-align: center;
}

.image-gallery .image-box .details .title {
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: 600;
  position: relative;
  top: -5px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}

.image-gallery .image-box .details .category {
  font-size: 18px;
  font-weight: 400;
  position: relative;
  bottom: -5px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}

.image-gallery .image-box:hover .details .title {
  top: 0px;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s 0.2s ease;
}

.image-gallery .image-box:hover .details .category {
  bottom: 0;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s 0.2s ease;
}

.image-gallery .image-box .details .title a,
.image-gallery .image-box .details .category a {
  color: #222222;
  text-decoration: none;
}

/* Let's make it responsive */
@media (max-width: 768px) {
  .image-gallery {
    grid-template-columns: unset;
    /* grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); */
    /* grid-template-columns: unset; */
    /* grid-auto-rows: 250px; */
    grid-auto-rows: unset;
  }

  /* .image-gallery .image-box:nth-child(7n + 1) {
    grid-column: unset;
    grid-row: unset;
  } */
}
