.navbarBg {
  background-color: #ffe1a1;
  height: 78px;
  /* width: 100vw; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 24px;
}
.logoutBtn {
  background-color: #eea300;
  padding: 10px 25px;
  border: none;
  outline: none;
  border-radius: 10px;
  color: #fff;
}
.thumbnail {
  cursor: pointer;
}
.thumbnail img {
  padding: 0 6px;
  opacity: 0.4;
}
.thumbnail .active  {
  opacity: 1;
}

.cards {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); */
  grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  /* gap: 20px; */
  margin-bottom: 50px;
  justify-items: center;
}
@media (max-width: 768px) {
  .cards {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }
}

@media (max-width: 640px) {
  .cards {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }
}
