.loginForm form input {
  width: 500px;
  border: none;
  outline: none;
  padding: 15px 25px;
  border-radius: 15px;
}
.loginForm form input[type="password"] {
  padding: 15px 38px 15px 25px;
}
.loginForm form input[type="submit"] {
  background-color: #352210;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  font-size: 19px;
  letter-spacing: 1px;
  cursor: pointer;
}
.loginForm form input[type="submit"]:disabled {
  cursor: not-allowed;
  background-color: #4d5157;
}
.errorMsg {
  font-size: 13px;
  text-align: center;
}

.otpForm form input {
  border: none;
  outline: none;
}
.otpForm form input[type="number"]::-webkit-inner-spin-button,
.otpForm form input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.otpForm form input[type="submit"] {
  background-color: #352210;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  font-size: 19px;
  letter-spacing: 1px;
  cursor: pointer;
  width: 100%;
}
.otpForm form input[type="submit"]:disabled {
  cursor: not-allowed;
  background-color: #4d5157;
}

.forgotpasswordField {
  /* width: 500px; */
  width: 100%;
  border: none;
  outline: none;
  padding: 15px 25px;
  border-radius: 15px;
}

@media (max-width: 768px) {
  .loginForm form input {
    width: 380px;
  }
}

@media (max-width: 420px) {
  .loginForm form input {
    width: 300px;
  }
}
